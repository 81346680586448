//react
import { useState } from "react";
//component
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import PrivacyPolicyPopupModel from "../../components/PrivacyPolicyPopupModel/PrivacyPolicyPopupModel";
//data
import { fqaData } from "../../assets/data/fqaData";
//style

import "./FaqPage.scss";
import { Router, useNavigate } from "react-router-dom";

const FaqPage = () => {
  const [isShow, setIsShow] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <TitleHeader title={"常見問答"} />
      <div className="faq-main-container main-body-spacing">
        <div className="faq-main r-16">
          {fqaData?.map((ques, i) => {
            return (
              <div key={`qa-${i}`} className="fqa-question">
                <div className="title sb-16">{ques?.question}</div>
                <div className="anwswer">{ques?.answer}</div>
              </div>
            );
          })}
          <div className="privacy-link" onClick={() => navigate("/privacy-policy")}>
            隱私權政策連結
          </div>
        </div>
      </div>
      {isShow && (
        <div className="popup-backdrop">
          <PrivacyPolicyPopupModel setShowPolicyTwo={setIsShow} />
        </div>
      )}
    </>
  );
};

export default FaqPage;
