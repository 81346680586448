//react
import { Route, Routes } from "react-router-dom";
//components
import HomePage from "../pages/HomePage/HomePage";
import PopularPostsPage from "../pages/PopularPostsPage/PopularPostsPage";
import PublishPage from "../pages/PublishPage/PublishPage";
import ChatListPage from "../pages/ChatListPage/ChatListPage";
import MyInfoPage from "../pages/MyInfoPage/MyInfoPage";
import EditInfoPage from "../pages/EditInfoPage/EditInfoPage";
import MembershipPage from "../pages/MembershipPage/MembershipPage";
import ServiceCenter from "../pages/ServiceCenter/ServiceCenter";
import UserInfoPage from "../pages/UserInfoPage/UserInfoPage";
import EditPostPage from "../pages/EditPostPage/EditPostPage";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import HashTagPostsPage from "../pages/HashTagPostsPage/HashTagPostsPage";
import FaqPage from "../pages/FaqPage/FaqPage";
import NavigateToPostPage from "../pages/NavigateToPostPage/NavigateToPostPage";
import DownloadAppPage from "../pages/DownloadAppPage/DownloadAppPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage/PrivacyPolicyPage";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

export const Main = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<HomePage />}></Route>
        <Route path="/trending-posts" element={<PopularPostsPage />}></Route>
        <Route path="/publish" element={<PublishPage />}></Route>
        <Route path="/re-publish" element={<PublishPage />}></Route>
        <Route path="/message-main" element={<ChatListPage />}></Route>
        <Route path="/myinfo/:userId" element={<MyInfoPage />}></Route>
        <Route path="/editinfo" element={<EditInfoPage />}></Route>
        <Route path="/membership" element={<MembershipPage />}></Route>
        <Route path="/support-center" element={<ServiceCenter />}></Route>
        <Route path="/userinfo/:userId" element={<UserInfoPage />}></Route>
        <Route path="/editpost" element={<EditPostPage />}></Route>
        <Route path="/about-us" element={<AboutUsPage />}></Route>
        <Route path="/faq" element={<FaqPage />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />}></Route>
        <Route
          path="/hashtag-posts/:hashTag"
          element={<HashTagPostsPage />}
        ></Route>
        <Route path="/post/:id" element={<NavigateToPostPage />}></Route>
        <Route path="/download-highlight" element={<DownloadAppPage />}></Route>
      </Routes>
    </>
  );
};
