//react
import { Dispatch, SetStateAction } from "react";
//data
import { privacyPolicy } from "../../assets/data/policyData";
//utility
import closePopup from "../../utils/closePopup";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";

import "./PrivacyPolicyPage.scss";

interface PrivacyPolicyPageProps {}

const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = (props) => {
  return (
    <div className="policy-page-main" style={{ padding: "32px" ,marginLeft: "260px", display: "flex", flexDirection: "column", gap: "16px" }}>
      <div className="sec-title">
        <h6 className="sb-24">
          網站隱私權政策 - Highlight 最精采的影音房地產交易
        </h6>
      </div>
      <div className="update-date r-14">最後更新時間： 2023 年 12 月 4 日</div>
      <div className="policy-list" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {privacyPolicy.map((policy, i) => {
          return (
            <div key={`policy-${i}`} className="sb-16">
              <div className="policy-title">{policy.title}</div>
              <ul className="article-list">
                {policy.policies.map((article, i) => {
                  return (
                    <li key={`article-${i}`} className="r-14">
                      {article}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
